<template>
  <footer>
    <p>©2024 — Premium Weight Loss Peptides</p>
  </footer>
</template>

<script>
export default {
  name: "Footer",

  data() {
    return {};
  },

  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
