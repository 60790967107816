<template>
  <div class="loginOuter" style="height: 94vh">
    <div class="loginForm custom-form">
      <div class="logo-heaer">
        <img src="../assets/img/logo.png" />
      </div>
      <form class="loginField" @submit.prevent="submitForm">
        <div
            class="alert alert-danger"
            :class="{ 'alert-active': errorMessage != null }"
          >
            <strong>Error!</strong> {{ errorMessage }}
          </div>
        <div
            class="alert alert-success"
            :class="{ 'alert-active': successMessage != null }"
          >
            <strong>Success!</strong> {{ successMessage }}
          </div>  
        <div id="emailDiv">          
          <div class="inputCol">
            <label
              >Email
              <span class="text-danger error-text">
                *
                <span v-if="v$.user_email.$error"> Email is required </span>
              </span></label
            >
            <input
              v-model="formFields.user_email"
              type="email"
              class="custom-field"
              :class="{ errorCustomField: v$.user_email.$error }"
              required
              placeholder="Please Enter Email"
            />
          </div>

          <div class="button-outer inputCol">          
            <button class="custom-button">Next</button>
          </div>
        </div>
      </form>

      <div id="passDiv">
        <div class="inputCol">
          <label
            >OTP
            <span class="text-danger error-text">
              *
              <span v-if="v$.user_email.$error">OTP is required </span>
            </span></label
          >
          <input
            v-model="formFields.user_password"
            type="password"
            class="custom-field"
            :class="{ errorCustomField: v$.user_password.$error }"
            required
            placeholder="Please Enter OTP."
          />
        </div>

        <div class="button-outer inputCol">
          <button class="custom-button" id="resendbtn" @click="resend">Resend</button>
            &nbsp;&nbsp;
          <button class="custom-button" @click="checkOTP">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import callService from "@/services/Service";
const { getData, getByID, postData } = callService();
export default {
  name: "PatientLogin",
  data() {
    return {
      userGroup: [],
      userType: [],
      errorMessage: null,
      successMessage: null,
    };
  },
  setup() {
    const formFields = reactive({
      user_email: "",
      user_password: "",
    });

    const rules = {
      user_email: {
        required,
      },
      user_password: {
        required,
      },
    };
    const v$ = useVuelidate(rules, formFields);
    return {
      formFields,
      v$,
    };
  },
  async created() {
    if (localStorage.getItem("LogIn")) {
      const type_uid = JSON.parse(localStorage.getItem("LogIn")).type_uid;
      const redirction = type_uid === "ipg4bi4v10" ? "enrollmentform" : "login";
      this.$router.push({
        name: redirction,
      });
    }
  },
  mounted(){
      localStorage.setItem("patient_uid",'');
  },
  methods: {
    async selectGroup(uid) {
      console.log("uid", uid);
      try {
        const resData = await getByID("login/getUserById/", uid);
        if (resData.status === 200 && resData.data.statuscode === 1) {
          this.userType = resData.data.result;
        }
      } catch (e) {
        this.error = e;
      }
    },    
    hideMsg() {
      setTimeout(
        () => ((this.successMessage = null), (this.errorMessage = null)),
        3000
      );
    },
    async submitForm() {      
      try {
          const responseState = await postData(
            "patient/getOTP",
            {
              email_address: this.formFields.user_email,              
            }
          );
          if (
            responseState.status === 200 &&
            responseState.data.statuscode === 1
          ) {
            this.successMessage = "OTP has been send to "+this.formFields.user_email;
            $("#emailDiv").hide();
            $("#passDiv").show();
            $("#resendbtn").show();
            this.hideMsg();
          }
          if (
            responseState.status === 200 &&
            responseState.data.statuscode === 0
          ){
            this.errorMessage = responseState.data.message;
            this.hideMsg();
          }
        } catch (e) {
          this.error = e;
          this.errorMessage = "Something Went Wrong.";
        }
      
    },
    async resend(){
        try {
          const responseState = await postData(
            "patient/getOTP",
            {
              email_address: this.formFields.user_email,              
            }
          );
          if (
            responseState.status === 200 &&
            responseState.data.statuscode === 1
          ) {
            this.successMessage = "OTP has been resend to "+this.formFields.user_email;
            $("#emailDiv").hide();
            $("#passDiv").show();
            $("#resendbtn").show();
            this.hideMsg();
          }
          if (
            responseState.status === 200 &&
            responseState.data.statuscode === 0
          ){
            this.errorMessage = responseState.data.message;
            this.hideMsg();
          }
        } catch (e) {
          this.error = e;
          this.errorMessage = "Something Went Wrong.";
        }
    },
    async checkOTP(){
      try {
          const responseState = await postData(
            "patient/verifyOTP",
            {
              email_address: this.formFields.user_email, 
              otp: this.formFields.user_password,             
            }
          );
          if ( responseState.status === 200 && responseState.data.statuscode === 1) {
            console.log(responseState.data);
            localStorage.setItem("patient_uid",responseState.data.patient_uid);
            this.$router.push({
              name: "PatientDashboard",
            });      
          }
          if (
            responseState.status === 200 &&
            responseState.data.statuscode === 0
          ){
            this.errorMessage = responseState.data.message;
            this.hideMsg();
          }
        } catch (e) {
          this.error = e;
          this.errorMessage = "Something Went Wrong.";
        }
      
    },
  },
};
</script>
<style scoped>
#passDiv {
  display: none;
}
#resendbtn {
  display: none;
}
.hClass {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #999393;
  line-height: 0.1em;
  margin: 18px 0 22px;
}

.hClass span {
  background: #fff;
  padding: 0 10px;
}
</style>
